@import "../_base/shape";
@mixin progress-radial-color($color) {
    $backColor: $gray-lighter;
    $barColor: $color;
    $step: 1;
    $loops: round(100 / $step);
    $increment: 360 / $loops;
    $half: round($loops / 2);
    @for $i from 0 through $loops {
        &[data-progress="#{$i*$step}"] {
            @if $i < $half {
                $nextdeg: 90deg + ($increment * $i);
                background-image: linear-gradient(90deg, $backColor 50%, transparent 50%, transparent), linear-gradient($nextdeg, $barColor 50%, $backColor 50%, $backColor);
            }
            @else {
                $nextdeg: -90deg + ($increment * ($i - $half));
                background-image: linear-gradient($nextdeg, $barColor 50%, transparent 50%, transparent), linear-gradient(270deg, $barColor 50%, $backColor 50%, $backColor);
            }
        }
    }
    >.overlay {
        color: $color;
    }
}

.progress-radial {
    @extend .circle;
    display: flex;
    align-items: center;
    margin: 10px auto;
    justify-content: center;
    &>& {
        width: 85%;
        height: 85%;
    }
}

.progress-radial>.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 85%;
    width: 85%;
    border-radius: 50%;
    background-color: white;
    font-size: 2em;
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: 1.5em;
    }
    @media (min-width: $screen-md-min) {
        font-size: 2.5em;
    }
}

.progress-radial-default {
    @include progress-radial-color($base-color);
}

.progress-radial-primary {
    @include progress-radial-color($brand-primary);
}

.progress-radial-success {
    @include progress-radial-color($brand-success);
}

.progress-radial-warning {
    @include progress-radial-color($brand-warning);
}

.progress-radial-danger {
    @include progress-radial-color($brand-danger);
}