.timeline {
    position: relative;
    padding: 20px 0;
    list-style: none;
    &::before {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 3px;
        margin-left: -1.5px;
        background-color: #eee;
    }
    >li {
        position: relative;
        margin-bottom: 20px;
        &::before {
            content: " ";
            display: table;
        }
        &::after {
            content: " ";
            display: table;
            clear: both;
        }
        &::before {
            content: " ";
            display: table;
        }
        &::after {
            content: " ";
            display: table;
            clear: both;
        }
        > {
            .timeline-panel {
                float: left;
                position: relative;
                width: 46%;
                padding: 20px;
                border: 1px solid #d4d4d4;
                border-radius: 2px;
                -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
                &::before {
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    top: 26px;
                    right: -15px;
                    border-top: 15px solid transparent;
                    border-right: 0 solid #ccc;
                    border-bottom: 15px solid transparent;
                    border-left: 15px solid #ccc;
                }
                &::after {
                    content: " ";
                    display: inline-block;
                    position: absolute;
                    top: 27px;
                    right: -14px;
                    border-top: 14px solid transparent;
                    border-right: 0 solid #fff;
                    border-bottom: 14px solid transparent;
                    border-left: 14px solid #fff;
                }
            }
            .timeline-badge {
                z-index: 100;
                position: absolute;
                top: 16px;
                left: 50%;
                width: 50px;
                height: 50px;
                margin-left: -25px;
                border-radius: 50%;
                text-align: center;
                font-size: 1.4em;
                line-height: 50px;
                color: #fff;
                background-color: #999;
            }
        }
        &.timeline-inverted>.timeline-panel {
            float: right;
            &::before {
                right: auto;
                left: -15px;
                border-right-width: 15px;
                border-left-width: 0;
            }
            &::after {
                right: auto;
                left: -14px;
                border-right-width: 14px;
                border-left-width: 0;
            }
        }
    }
}

.timeline-badge {
    &.primary {
        background-color: #2e6da4 !important;
    }
    &.success {
        background-color: #3f903f !important;
    }
    &.warning {
        background-color: #f0ad4e !important;
    }
    &.danger {
        background-color: #d9534f !important;
    }
    &.info {
        background-color: #5bc0de !important;
    }
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body> {
    p,
    ul {
        margin-bottom: 0;
    }
    p+p {
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    ul.timeline {
        &::before {
            left: 40px;
        }
        >li> {
            .timeline-panel {
                width: calc(100% - 90px);
                width: -moz-calc(100% - 90px);
                width: -webkit-calc(100% - 90px);
            }
            .timeline-badge {
                top: 16px;
                left: 15px;
                margin-left: 0;
            }
            .timeline-panel {
                float: right;
                &::before {
                    right: auto;
                    left: -15px;
                    border-right-width: 15px;
                    border-left-width: 0;
                }
                &::after {
                    right: auto;
                    left: -14px;
                    border-right-width: 14px;
                    border-left-width: 0;
                }
            }
        }
    }
}