@import "./_base/base";
// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
// Plugin
@import "~metismenu/src/metisMenu";
@import "~select2/src/scss/core";
@import "~select2-bootstrap-theme/src/select2-bootstrap";
@import "~datatables.net-bs/css/dataTables.bootstrap";
@import "~datatables.net-responsive-bs/css/responsive.bootstrap";
@import "~flatpickr/dist/flatpickr.css";
@import "~bootstrap3-dialog/src/css/bootstrap-dialog.css";
@import "~bootstrap-social/bootstrap-social.scss";
//// Fonticon
@import "~font-awesome/scss/font-awesome";
@import "~simple-line-icons/scss/simple-line-icons";
// Template
@import "./_base/navbar";
@import "./_base/table";
@import "./dashboard/dashboard";
