@import url('https://fonts.googleapis.com/css?family=Exo:200,400,700|Martel:200,400|Roboto+Mono:300,400');
$font-family-sans-serif: Exo,
"Helvetica Neue",
Helvetica,
Arial,
sans-serif !default;
$font-family-serif: Martel,
Georgia,
"Times New Roman",
Times,
serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: "Robot Mono",
Menlo,
Monaco,
Consolas,
"Courier New",
monospace !default;
$blue-nightlight: #1a2023 !default;
$gray-base: #000 !default;
$gray-darkest: lighten($gray-base,
10%);
$gray-darker: lighten($gray-base,
13.5%) !default; // #222
$gray-dark: lighten($gray-base,
20%) !default; // #333
$gray: lighten($gray-base,
33.5%) !default; // #555
$gray-light: lighten($gray-base,
46.7%) !default; // #777
$gray-lighter: lighten($gray-base,
93.5%) !default; // #eee
$gray-lightest: lighten($gray-base,
97.25%) !default;
$base-color: $gray-darker !default;
$text-color: $gray-darker !default;
$text-color-inverse: $gray-lighter !default;
$brand-primary: #3498db !default; // #337ab7
$brand-success: #2bce70 !default;
$brand-info: #3ca4cc !default;
$brand-warning: #f1c40f !default;
$brand-danger: #e74c3c !default;
$border-radius-base: 0 !default;
$border-radius-large: 0 !default;
$border-radius-small: 0 !default;
$font-size-base: 14px !default;
//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px
$input-height: ($font-size-base * 1.3)+$line-height-computed+8.8px !default; // 49
$btn-spinner-size: ($input-height / 2)+0px !default;
//** Animation